import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import axios from 'axios';
import _ from 'lodash'
import { authCode } from '../../../utils/auth';
import { DataGrid, GridAddIcon, GridToolbarContainer,GridToolbarColumnsButton, GridToolbarFilterButton,GridToolbarDensitySelector, GridToolbarQuickFilter, GridToolbarExport } from '@mui/x-data-grid';
import {  Container,Typography, Stack, Button, Tooltip, Collapse, Box } from '@mui/material';
import {  Alert, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions  } from '@mui/material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddOrderForm from '../../Forms/addorder';
import { extramileregions } from '../../../actions';



function CustomToolbar() {

  const [open, setOpen] = useState(false);

  const handleAddOrderClick= () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

  };


  return (
    <GridToolbarContainer>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button color="primary" startIcon={<GridAddIcon />} onClick={handleAddOrderClick}>
            Add Order
      </Button>
      <AddOrderForm open={open} handleClose={handleClose} handleAddOrder={() => {}} />
    </Box>
    </GridToolbarContainer>
  );
}


export default function ExtOrdersTable() {
  const [orders, setOrders] = useState([])
  const [loading, setIsLoading] = useState(false) 
  const [pageSize, setPageSize] = useState(10);
  const [error, setError] = useState(null);
  const [showGrid, setShowGrid] = useState(true);
  const regions = useSelector((state) => _.values(state.extregions.data))
  const dispatch = useDispatch()

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [
    { field:'redeem_id', headerName:'Redeem ID', width: 120, editable:false, hide:true },
    { field:'order_for', headerName:'Order For', width: 180, editable: true },
    { field:'acct_id', headerName:'Account ID', width: 180, editable: true },
    { field: 'product_id', headerName: 'Product ID', width: 100,align:'center', editable: false, hide:true },
    { field: 'points', headerName: 'Points Value', type: 'number',align:'center', editable: false },
    { field:'picture_url', headerName:'Product Image', width: 180,align:'center', renderCell: (params)=>(<img src={params.formattedValue} width='125' height='80' alt='order product'/>), editable:true },
    { field: 'product_name', headerName: 'Product name', width: 180, editable: false, renderCell: (params) => (
      <Tooltip title={params.value}>
        <div>{params.value}</div>
      </Tooltip>
    ) },
    { field: 'color', headerName: 'Color Options', width: 150, editable: true, headerAlign:"center", align:"center"  },
    { field: 'size', headerName: 'Size Options', width: 100, type:'singleSelect', editable: true, headerAlign:"center", align:"center" },
    { field: 'add_options', headerName: 'Add Options', width: 180, editable: true,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <div>{params.value}</div>
      </Tooltip>
    )},
    { field: 'dollar_value', headerName: 'Cost(DollarValue)', width: 180, editable: true, hide:true },
    { field: 'region', headerName: 'Region', width: 180, type:'singleSelect', editable: true, cellContent:true, valueOptions: (regions) => regions.map((item) => item.regions),
     renderCell: (params) => (
      <Tooltip title={params.value}>
        <div>{params.value}</div>
      </Tooltip>
    )},
    { field: 'org_number', headerName: 'Org Number', width: 100, editable: true, headerAlign:"center", align:"center"},
    { field: 'ordered', headerName: 'Warehouse Ordered', width: 180, type: 'date', editable: true, valueFormatter: params => params.value === null ? '' : moment(params.value).format("MM/DD/YYYY")},
    { field: 'distributed', headerName: 'Admin Received', width: 180, type: 'date', editable: true, valueFormatter: params => params.value === null ? '' : moment(params.value).format("MM/DD/YYYY")},
    { field: 'claimed', headerName: 'Employee Received', width: 180, type: 'date', editable: true, valueFormatter: params => params.value === null ? '' : moment(params.value).format("MM/DD/YYYY")},
    { field: 'order_period', headerName: 'Order Period', width: 180, editable: true, type:'date', renderCell:(value)=>(<strong><i>{moment(value.row.order_period).format('MMM-DD-YYYY')}</i></strong>)},
    { field: 'email', headerName:'Email', width: 180, editable: true },
    { field: 'created_date', headerName: 'Created Date', width: 180, editable: false, type:'date', valueGetter: (params) => moment(params.value).format('MMM-DD-YYYY')},
    { field: 'created_by', headerName: 'Created By', width: 180, editable: true, hide:true, },
    { field: 'modified_date', headerName: 'Modified Date', width: 180, editable: false, hide:true },
    { field: 'modified_by', headerName: 'Modified By', width: 180, editable: false, hide:true },
    {
      field: 'actions',
      headerName: 'Delete ',
      width: 100,
      renderCell: (params) => (
        <Button style={{color: 'red'}}  onClick={() => { setOpenDeleteModal(true); setSelectedRow(params.row); }}>
          Delete
        </Button>
      ),
    }
  ];

  const fetchOrders = async () => {
    try {

      let token = JSON.parse(authCode()).accessToken
      setIsLoading(true)
      const response = await axios.get(process.env.REACT_APP_EXTRAMILE_URL + '/admin/orders', {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
      })

      const data = response.data.rows;
      if (Array.isArray(data)) {
        setOrders(data);
        setIsLoading(false);
      } else {
        setOrders([]);
        setError('API Error')
      }

    } catch (error) {
      setOrders([]);
      setError(error)
      return setIsLoading(false)
    }
  };

  const updateOrders = async (data) => {
    try {
      let token = JSON.parse(authCode()).accessToken
      await axios.post(process.env.REACT_APP_EXTRAMILE_URL + '/admin/orders/update', data,
      {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      })
      return setIsLoading(false)
    } catch (error) {
      setError(error)
      return setIsLoading(false)
    }
  }

  const reloaddata = async () => {
    fetchOrders()
  }

  useEffect(() => {
    fetchOrders();
    dispatch(extramileregions())
  }, [dispatch]);

  console.log(error)

  const handleEditCellChangeCommitted = async (data) => {
    
    let dateFields = ['ordered', 'distributed', 'claimed', 'order_period'];

    if (dateFields.includes(data.field)) {
      let date = new Date(data.value);
      let formattedDate = date.toISOString().slice(0,10);
      let newValue = formattedDate === '1970-01-01' ? null : formattedDate
      let newData = {...data, value: newValue}
      return await updateOrders(newData)
    }

    else {
      return await updateOrders(data)
    }
  };

  const handleApiError = () => {
    setShowGrid(false);
  };

  const handleDeleteTransaction = async (row) => {
    // Make API request to delete transaction
    let token = JSON.parse(authCode()).accessToken;
    await axios.delete(
      process.env.REACT_APP_EXTRAMILE_URL + '/admin/orders/delete',
      {
        data: row,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    );
    reloaddata()
    // Close the modal
    setOpenDeleteModal(false);
    // setOrders(orders.filter(orders => orders.redeem_id !== row.redeem_id))
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h6" gutterBottom component="div">Extramile Orders</Typography>
      <br/>
      <Button size="small" onClick={reloaddata}>
        <RefreshIcon fontSize="medium" color='primary'/>
        Refresh Table
      </Button>
      <Collapse in={showGrid}>
      <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
        <DataGrid
          loading={loading}
          forceUpdate
          checkboxSelection
          onError={handleApiError}
          density='standard'
          sx={{ overflowX: 'scroll' }}
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20,50,100]}
          rows={orders}
          rowHeight={100}
          getRowId={(row) => row?.redeem_id}
          columns={columns.map((column) => {
            if (column.field === 'region') {
              return { ...column, valueOptions: column.valueOptions(regions) };
            } else {
              return column;
            }
          })}
          onCellEditCommit	={handleEditCellChangeCommitted} 
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Error connecting. Please refresh the page or try again later.
              </Stack> )
          }} 
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            }
          }}
        />
      </div>
      </Collapse>
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={handleDeleteTransaction}
        row={selectedRow}
      />
  </Container>
  );
}

function DeleteConfirmationModal({ open, onClose, onDelete, row }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="error">Are you sure you want to delete this order? This action cannot be undone!</Alert>
          {row && 
          <Table>
            <TableBody>
              {Object.entries(row).map(([key, value]) => 
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell align="left">
                    <strong>{value}</strong>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onDelete(row)} style={{color: 'red'}} >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}