import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'
import axios from 'axios';
import _ from 'lodash'
import { authCode } from '../../../utils/auth';
import { extramileregions } from '../../../actions';
import { DataGrid, GridToolbarContainer,GridToolbarColumnsButton, GridToolbarFilterButton,GridToolbarDensitySelector, GridToolbarQuickFilter, GridToolbarExport, GridAddIcon } from '@mui/x-data-grid';
import {  Button, Alert, Typography, Tooltip, Collapse, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions  } from '@mui/material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Container, Stack } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddTxForm from '../../Forms/addtransaction';
import { CircularProgress } from '@mui/material';

/*
  ? Add new tx's required?
  * Yes
  ? Are transactions deletable ?
  * Yes, a hard delete is the requirement for deleting transactions given it is a secondary table & transactions are not important in any way.
*/

function EditToolbar() {

  const [open, setOpen] = useState(false);

  const handleAddUserClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

  };

  return (
    <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Button color="primary" startIcon={<GridAddIcon />} onClick={handleAddUserClick}>
            Add Transaction
        </Button>
        <AddTxForm open={open} handleClose={handleClose} handleAddUser={() => {}} />
      </Box>
      <Box>
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  )
}

export default function ExtTransactionsTable() {

  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const [pageSize, setPageSize] = useState(10);
  const regions = useSelector((state) => _.values(state.extregions.data))
  const [, setError] = useState(null);
  const [showGrid, setShowGrid] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);


  const columns = [

    { field: 'tx_id', headerName: 'Transaction ID', width: 100, editable: false, hide: true},
    { field: 'acct_id', headerName: 'Account ID', width: 180, editable: false, hide: true },
    { field: 'tx_type', headerName: 'Tx Type', width: 100, editable: false },
    { field: 'acct_from', headerName: 'Acct From', width: 180, editable: true, hide:true },
    { field: 'email_from', headerName: 'Giver Email', width: 180, editable: true},
    { field: 'giver_name', headerName: 'Giver Name', width: 180, editable: true },
    { field: 'acct_to', headerName: 'Acct To', width: 180, editable: true, hide:true },
    { field: 'email_to', headerName: 'Receiver Email', width: 180, editable: true},
    { field: 'receiver_name', headerName: 'Receiver Name', width: 180, editable: true },
    { field: 'amount', headerName: 'Amount',type:'number', width: 75, editable: true, align:'center' },
    { field: 'core_value', headerName: 'Core Value', type:'singleSelect', width: 150, editable: true, valueOptions: ["Collaboration","Dedication","Education","Fiscal Responsibility","Integrated Transportation","Integrity","N/A","Passion","Quality","Safety","Transparency","Trust"] },
    { field: 'giver_group', headerName: 'Giver group',type:'singleSelect', width: 200, editable: true, valueOptions: (regions) => regions.map((item) => item.regions),
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <div>{params.value}</div>
      </Tooltip>
    )},
    { field: 'receiver_group', headerName: 'Receiver Group', type:'singleSelect', width: 200, editable: true,valueOptions: (regions) => regions.map((item) => item.regions),
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <div>{params.value}</div>
      </Tooltip>
    )},
    { field: 'comments', headerName: 'Comments', width: 180, editable: true, renderCell: (params) => (<Tooltip title={params.value}><div>{params.value}</div></Tooltip>)},
    { field: 'admin_notes', headerName: 'Admin Notes', width: 180, editable: true, renderCell: (params) => (<Tooltip title={params.value}><div>{params.value}</div></Tooltip>)},
    { field: 'created_date', headerName: 'Created Date', width: 180,type:'date', editable: false, valueGetter: (params) => moment(params.value).format('MMM-DD-YYYY')},
    { field: 'created_by', headerName: 'Created By', width: 180, editable: false, hide:true },
    { field: 'modified_date', headerName: 'Modified Date',type:'date', valueGetter: (params) => moment(params.value).format('MMM-DD-YYYY'), width: 180, editable: false, hide:true},
    { field: 'modified_by', headerName: 'Modified By', width: 180, editable: false, hide:true },
    {
      field: 'actions',
      headerName: 'Delete ',
      width: 100,
      renderCell: (params) => (
        <Button style={{color: 'red'}}  onClick={() => { setOpenDeleteModal(true); setSelectedRow(params.row); }}>
          Delete
        </Button>
      ),
    }
  ];


  const fetchTransactions = async () => {
    try {
      let token = JSON.parse(authCode()).accessToken;
      setIsLoading(true);
      dispatch(extramileregions());
      
      const response = await axios.get(process.env.REACT_APP_EXTRAMILE_URL + '/admin/transactions', {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
      console.log('TXRESPONSE=>', response.data)
      const txns = response.data.data;
      if (Array.isArray(txns)) {
        setTransactions(txns);
        setIsLoading(false);
      } else {
        setTransactions([]);
        setError('API Error')
      }

    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  const updateTransactions = async (data) => {
    try {
      let token = JSON.parse(authCode()).accessToken;
      await axios.post(process.env.REACT_APP_EXTRAMILE_URL + '/admin/transactions/update', data, {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }).catch((error) => {
        setIsLoading(false);
        setTransactions([]);
        setError(error);
      });
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };
  
  const reloaddata = async () => {
    fetchTransactions();
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const handleEditCellChangeCommitted = async (data) => {
    await updateTransactions(data);
    return null;
  };

  const handleApiError = () => {
    setShowGrid(false);
  };

  const handleTransactionDelete = () => {
    fetchTransactions();
  }

  return (
      <Container maxWidth={false}>
        <Typography variant="h6" gutterBottom component="div">Extramile Transactions</Typography>
        <br/>
        <Button size="small" onClick={reloaddata}>
          <RefreshIcon fontSize="medium" color='primary'/>
          Refresh Table
        </Button>
        <Collapse in={showGrid}>
        <div style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
        <DataGrid
          loading={isLoading}
          onError={handleApiError}
          forceUpdate
          checkboxSelection
          density='standard'
          sx={{ overflowX: 'scroll' }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20,50,100]} 
          rows={transactions}
          getRowId={(row) => row?.tx_id} 
          columns={columns.map((column) => {
            if (column.field === 'giver_group' || column.field === 'receiver_group') {
              return { ...column, valueOptions: column.valueOptions(regions) };
            } else {
              return column;
            }
          })}
          onCellEditCommit	={handleEditCellChangeCommitted} 
          components={{
            Toolbar: EditToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Error connecting. Please refresh the page or try again later.
              </Stack> )
          }} 
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
        </div>
        </Collapse>
        <DeleteConfirmationModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          row={selectedRow}
          setOpenDeleteModal={setOpenDeleteModal}
          onTransactionDelete={handleTransactionDelete}
        />
      </Container>
  );
}

function DeleteConfirmationModal({ open, onClose, row, setOpenDeleteModal, onTransactionDelete  }) {
  const [apiLoading, setApiLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleDeleteTransaction = async (row) => {
    setApiLoading(true);
    let token = JSON.parse(authCode()).accessToken;
    try {
      await axios.delete(
        process.env.REACT_APP_EXTRAMILE_URL + '/admin/transactions/delete',
        {
          data: row,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      )
      // Close the modal
      setSuccessMessage("Transaction deleted successfully.");
      setTimeout(() => {
        setApiLoading(false);
        setOpenDeleteModal(false);
        onTransactionDelete();
      }, 3000);
    } catch (error) {
      setErrorMessage("An error occurred while deleting the transaction. Please try again.");
      setApiLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="error">Are you sure you want to delete this transaction? This action cannot be undone!</Alert>
          {row && 
          <Table>
            <TableBody>
              {Object.entries(row).map(([key, value]) => 
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell align="left">
                    <strong>{value}</strong>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {apiLoading ? <CircularProgress size={24} /> : 
          <>
          <Button onClick={onClose} color="primary" disabled={apiLoading}>
            Cancel
          </Button>
          <Button onClick={() => handleDeleteTransaction(row)} style={{color: 'red'}} disabled={apiLoading}>
            Delete
          </Button>
          </>
        }
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </DialogActions>
    </Dialog>
  );
}

